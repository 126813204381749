import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddReaction from '@mui/icons-material/AddReaction';

const KobrosAddCollectionAction = () => (
  <Stack
    spacing={2}
    alignItems="center"
    justifyContent="center"
  >
    <AddReaction sx={{ fontSize: 60, color: 'kobros.main' }} />
    <Typography textAlign="center" variant="h1" marginBottom={1}>
      Añadir {' '}
      <Typography variant="h1" sx={{ color: 'kobros.main' }} component="span">
        Gestión
      </Typography>
    </Typography>
    <Typography textAlign="center" variant="h2" sx={{ fontWeight: 'bold' }}>
      ¡Con koBros puedes dejar registro de tus gestiones de cobranza!
    </Typography>
    <Typography textAlign="center">
      Elige el tipo de gestión, el contacto con el que te comunicaste y
      deja un comentario explicativo.
      Así podrás revisar todo el historial de cobro de tus facturas
      para cobrar de manera más eficiente.
    </Typography>
  </Stack>
);

export default KobrosAddCollectionAction;
